import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useWithinBookableRange } from 'redux/reducers/withinBookableRangeReducer';
import Button from '../forms/Button';
import Loader from '../layouts/Loader';
import { Div, Hr, Row, Col } from '../utils/Helper';
import { SingleDate, TimeSlots } from './Day';
import { Center } from '../elements/Container';
import { moment } from '../../utils/momentUtils';
import Error from '../../views/error/pages/Error';
import messages from './i18n/calendarBody';

const FixedButton = styled(Button)`
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  &:hover {
    background-color: #907cff;
  }
  &:disabled {
    cursor: no-drop;
    background-color: #ccc7ea;
  }
`;

const Footer = styled(Row)`
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 104px;
  background: #fff;
  align-items: center;
  display: flex;
  z-index: 1;
`;

const Overlay = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  z-index: 1;
  border: solid 2px rgb(210, 233, 231, 0.65) !important;
`;

const NotBookableHeader = styled.span`
  display: block;
  padding: 50px 50px;
  font-family: NotoSansJP;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #68878d;
`;

export function CalendarBody({
  isTimeSlotLoading,
  timeSlotsHasError,
  timeSlotsError,
  isMultiTime,
  multiguestCalendarDetail,
  duration,
  onChange,
  onChangeMultiTime,
  timeSlots,
  timezone,
  businessWeekDays,
  isDebug,
  options: {
    isDateEarlier,
    calculateAvailableTime,
    getHoursOfTheDay,
    getJapaneseDay,
    getEnglishDay,
    currentWeek: { week },
  },
}) {
  const selectedSlots = useSelector(state => state.selectedSlots.slots);
  const withinBookableRange = useWithinBookableRange();
  const availableTimeSlots = calculateAvailableTime(
    timeSlots.availableTimeSlots,
    timezone,
  );

  if (timeSlotsHasError) {
    return <Error message={timeSlotsError} />;
  }

  if (isTimeSlotLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  /** if day has empty slots return empty array explicit */
  function handleEmptySlotDays(day, checkingTimeSlots) {
    if (checkingTimeSlots[day.format('MM-DD-yyyy')] === undefined) {
      return [];
    }
    return checkingTimeSlots[day.format('MM-DD-yyyy')];
  }

  return (
    <>
      {!withinBookableRange && (
        <Overlay>
          <NotBookableHeader>
            <FormattedMessage {...messages.offBookingRange} />
          </NotBookableHeader>
        </Overlay>
      )}
      <Div
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        width="100%"
      >
        {week.map(day => {
          const handledSlots = handleEmptySlotDays(day, availableTimeSlots);

          if (
            handledSlots.length === 0 &&
            !businessWeekDays[day.isoWeekday() - 1]
          )
            return null;

          return (
            <Div
              key={day.format('lll')}
              width="100%"
              bg={`${isDateEarlier(day, moment()) === 'equal' && '#e6f4ef'}`}
              px={['15px', '0px']}
              borderRadius={['0px', '10px']}
              pb={[null, '35px']}
            >
              <Div width="100%" px={['-15px', '0px']}>
                <Div
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width="100%"
                >
                  <SingleDate
                    date={day.date()}
                    day={day}
                    getJapaneseDay={getJapaneseDay}
                    getEnglishDay={getEnglishDay}
                    isDateEarlier={isDateEarlier}
                  />
                  <TimeSlots
                    hoursOfTheDay={getHoursOfTheDay}
                    day={day}
                    duration={duration}
                    timeSlots={handledSlots}
                    handleChange={onChange}
                    isMultiTime={isMultiTime}
                    multiguestCalendarDetail={multiguestCalendarDetail}
                    isDebug={isDebug}
                  />
                </Div>
                <Hr
                  display={['block', 'none']}
                  border="none"
                  height="2px"
                  bg="#d2e9e7"
                  mb="0px"
                />
              </Div>
            </Div>
          );
        })}
      </Div>
      {isMultiTime && (
        <Footer>
          <Col>
            <FixedButton
              disabled={selectedSlots.length === 0}
              width="340px"
              bg="#6e52ff"
              onClick={onChangeMultiTime}
            >
              <FormattedMessage {...messages.confirmBtn} />
            </FixedButton>
          </Col>
        </Footer>
      )}
    </>
  );
}

CalendarBody.defaultProps = {
  isTimeSlotLoading: false,
  timeSlotsHasError: false,
  timeSlotsError: '',
  duration: 60,
  timeSlots: {},
  options: {},
  timezone: 'Asia/Tokyo',
  isMultiTime: false,
  isDebug: false,
  multiguestCalendarDetail: [],
  onChangeMultiTime: () => {},
};

CalendarBody.propTypes = {
  isTimeSlotLoading: PropTypes.bool,
  timeSlotsHasError: PropTypes.bool,
  timeSlotsError: PropTypes.string,
  duration: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  timeSlots: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  timezone: PropTypes.string,
  businessWeekDays: PropTypes.arrayOf(PropTypes.bool).isRequired,
  isMultiTime: PropTypes.bool,
  isDebug: PropTypes.bool,
  onChangeMultiTime: PropTypes.func,
  multiguestCalendarDetail: PropTypes.instanceOf(Array),
};
