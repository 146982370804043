import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmBtn: {
    id: 'booking.bigCalendar.calendarBody.confirmDate',
    defaultMessage: 'Confirm candidate date',
  },
  offBookingRange: {
    id: 'booking.bigCalendar.calendarBody.offBookingRange',
    defaultMessage: 'No bookable time slots from here',
  },
});
