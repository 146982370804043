import { getDefaultTimezone } from '../utils/momentUtils';

export const globalActions = [
  {
    name: 'calendarDetail',
    url: undefined,
    query: undefined,
    body: undefined,
    reset: false,
    initialState: { isSuccess: false, hasError: false },
  },
  {
    name: 'duration',
    url: undefined,
    query: undefined,
    body: undefined,
    reset: false,
    initialState: {},
  },
  {
    name: 'loading',
    url: undefined,
    query: undefined,
    body: undefined,
    reset: false,
    initialState: { loading: true },
  },
  {
    name: 'selectedSlots',
    url: undefined,
    query: undefined,
    body: undefined,
    reset: false,
    initialState: { slots: [] },
  },
  {
    name: 'timezone',
    initialState: {
      timezone: { label: '', code: getDefaultTimezone() },
    },
  },
  {
    name: 'pntp',
    pntpAllowed: true,
  },
  {
    name: 'multiguestEvent',
    url: undefined,
    query: undefined,
    body: undefined,
    reset: false,
    initialState: { isSuccess: false, hasError: false },
  },
  {
    name: 'appointmentDetail',
    url: undefined,
    query: undefined,
    body: undefined,
    reset: false,
    initialState: { isSuccess: false, hasError: false, schedulable: true },
  },
  {
    name: 'withinBookableRange',
    withinBookableRange: true,
  },
];
