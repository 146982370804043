import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import humps from 'lodash-humps';
import { createAction } from '@reduxjs/toolkit';
import { defineMessage } from 'react-intl';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

import { _get } from 'utils/api';
import {
  setNotFirstTime,
  updateTimeSlots,
  resetTimeSlots as reset,
  resetTimeFullSlots as resetFull,
} from 'redux/reducers/timeSlotsReducer';
import { updateBookableRange } from 'redux/reducers/withinBookableRangeReducer';
import { getDefaultTimezone } from 'utils/momentUtils';
import { checkStatus } from 'utils/apiUtils';
import { debounce } from 'lodash';

const moment = extendMoment(Moment);

const messages = defineMessage({
  internalError: {
    id: 'error.common.internalError',
    defaultMessage: 'Help Center',
  },
});

function useAvailableTimeSlots(
  uid,
  duration,
  timeZone = getDefaultTimezone(),
  intl,
  heavyLoadCompany = false,
  debug = false,
) {
  const availableTimeSlots = useSelector(state => state.timeSlots);
  const enabledDays = useSelector(
    state => state.calendarDetail.businessWeekDays,
  );
  const [startOfWeek, setStartOfWeek] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const pntpAction = createAction('pntp_update');
  const dispatch = useDispatch();

  const notAvailableEventsCheck = events =>
    !(events || []).some(e => moment(e.startTime).isAfter(moment()));

  const fetchSlots = params => {
    const queryString = new URLSearchParams(params);
    const url = `booking_calendars/${uid}/available_time_slots?${queryString.toString()}`;
    return _get(url)
      .then(result => {
        const { data, status } = result;

        const payload = {
          ...humps(data),
          status,
          url,
          ...checkStatus(status),
        };

        dispatch(
          pntpAction({
            pntpAllowed: data.pntp === undefined ? true : data.pntp,
          }),
        );
        dispatch(
          updateBookableRange({
            withinBookableRange:
              data.within_bookable_range === undefined
                ? true
                : data.within_bookable_range,
          }),
        );
        return payload;
      })
      .catch(() => {
        return null;
      });
  };

  const fetchTimeSlotsAvailability = (timeMin, timeMax, firstTime) => {
    const queryParams = {
      duration,
      time_min: timeMin,
      time_max: timeMax,
      time_zone: timeZone,
      next_available_week: firstTime,
      debug,
    };

    if (firstTime) {
      dispatch(setNotFirstTime());
    }
    const queryString = new URLSearchParams(queryParams);

    const url = `booking_calendars/${uid}/available_time_slots?${queryString.toString()}`;
    _get(url)
      .then(result => {
        const { data, status } = result;

        const payload = {
          ...humps(data),
          status,
          url,
          ...checkStatus(status),
        };
        if (data.time_range != null) {
          setStartOfWeek(moment(data.time_range.start_time));
        }
        dispatch(updateTimeSlots(payload));
        dispatch(
          pntpAction({
            pntpAllowed: data.pntp === undefined ? true : data.pntp,
          }),
        );
        dispatch(
          updateBookableRange({
            withinBookableRange:
              data.within_bookable_range === undefined
                ? true
                : data.within_bookable_range,
          }),
        );
        setIsLoading(false);
      })
      .catch(() => {
        const payload = {
          ...humps({
            error: { message: intl.formatMessage(messages.internalError) },
          }),
          status: 500,
          ...checkStatus(500),
        };
        dispatch(updateTimeSlots(payload));
        setIsLoading(false);
      });
  };

  const fetchTimeSlotsAvailabilityDaily = (timeMin, timeMax, recall = 1) => {
    dispatch(setNotFirstTime());

    const now = moment();
    const range = moment.range(timeMin, timeMax);
    const dayRange = Array.from(range.by('days'))
      .filter(
        d => d.isSameOrAfter(now, 'day') && enabledDays[d.isoWeekday() - 1],
      )
      .map(d => d.toISOString(true));

    const promises = dayRange.map(day => {
      const queryParams = {
        duration,
        time_min: day,
        time_max: day,
        time_zone: timeZone,
        next_available_week: false,
        debug,
      };
      return fetchSlots(queryParams);
    });

    Promise.all(promises).then(days => {
      if (!days.some(v => v == null)) {
        const payload = days.reduce(
          (acc, response) => {
            return {
              error: acc.error != null ? acc.error : response.error,
              availableTimeSlots:
                response.availableTimeSlots != null
                  ? [...acc.availableTimeSlots, ...response.availableTimeSlots]
                  : acc.availableTimeSlots,
              isSuccess: acc.isSuccess && response.isSuccess,
              hasError: acc.hasError || response.hasError,
              firstTime: false,
            };
          },
          {
            availableTimeSlots: [],
            isSuccess: true,
            hasError: false,
          },
        );

        if (
          recall > 1 &&
          notAvailableEventsCheck(payload.availableTimeSlots.flat())
        ) {
          const newTimeMin = moment(timeMin).add(1, 'week');
          const newTimeMax = moment(timeMax).add(1, 'week');

          setStartOfWeek(newTimeMin);

          return fetchTimeSlotsAvailabilityDaily(
            newTimeMin.toISOString(true),
            newTimeMax.toISOString(true),
            recall - 1,
          );
        }
        dispatch(updateTimeSlots(payload));
      } else {
        const payload = {
          ...humps({
            error: { message: intl.formatMessage(messages.internalError) },
          }),
          status: 500,
          ...checkStatus(500),
        };
        dispatch(updateTimeSlots(payload));
      }
      setIsLoading(false);
      return null;
    });
  };

  const originalFetchAvailableTimeSlots = (timeMin, timeMax, firstTime) => {
    if (heavyLoadCompany) {
      return fetchTimeSlotsAvailabilityDaily(
        timeMin,
        timeMax,
        firstTime ? 10 : 1,
      );
    }

    return fetchTimeSlotsAvailability(timeMin, timeMax, firstTime);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAvailableTimeSlots = useCallback(
    debounce(
      (timeMin, timeMax, firstTime) =>
        originalFetchAvailableTimeSlots(timeMin, timeMax, firstTime),
      50,
    ),
    [],
  );

  const resetTimeSlots = () => {
    dispatch(reset());
  };

  const resetTimeSlotsFull = () => {
    dispatch(resetFull());
  };

  return {
    availableTimeSlots,
    isLoading,
    startOfWeek,
    actions: {
      fetchAvailableTimeSlots,
      resetTimeSlots,
      resetTimeSlotsFull,
    },
  };
}

export { useAvailableTimeSlots };
