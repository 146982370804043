import { createAction, createReducer } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const INITIAL_STATUS = {
  withinBookableRange: true,
};

export const useWithinBookableRange = () =>
  useSelector(state => state.withinBookableRange.withinBookableRange);
export const updateBookableRange = createAction('withinBookableRange_update');

const withinBookableRangeReducer = createReducer(
  { ...INITIAL_STATUS },
  {
    withinBookableRange_update: (state, action) => {
      return { ...action.payload };
    },
  },
);

export default withinBookableRangeReducer;
